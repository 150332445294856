import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import twitter from '../img/twitter_logo.svg';
import facebook from '../img/facebook_logo.svg';
import Layout from '../components/Layout';
import './about-page.scss';

export const AboutPageTemplate = ({ member, company, access, helmet }) => {
  return (
    <div className="content">
      {helmet || ''}
      <section className="member">
        <div className="member__inner">
          <div className="section-heading-blue">
            <h2>MEMBER</h2>
          </div>
          <ul className="member-list">
            <li>
              <div className="member-info">
                <div className="member-name">
                  <span>代表取締役社長</span>
                  <h4>{member.ceo_name}</h4>
                </div>
                <div className="member-career">
                  <p>{member.ceo_career}</p>
                </div>
              </div>
              <div className="member-social">
                <a
                  href={member.ceo_twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
                <a
                  href={member.ceo_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              </div>
            </li>
            <li>
              <div className="member-info">
                <div className="member-name">
                  <span>共同創業者</span>
                  <h4>{member.cco_name}</h4>
                </div>
                <div className="member-career">
                  <p>{member.cco_career}</p>
                </div>
              </div>
              <div className="member-social">
                <a
                  href={member.cco_twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
                <a
                  href={member.cco_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="company">
        <div className="company__inner">
          <div className="section-heading-white">
            <h2>COMPANY</h2>
          </div>
          <ul className="company-list">
            <li>
              <span>【会社名】</span>
              <p>{company.name}</p>
            </li>
            <li>
              <span>【設立】</span>
              <p>{company.establishment}</p>
            </li>
            <li>
              <span>【住所】</span>
              <p>{company.address}</p>
            </li>
            <li>
              <span>【事業内容】</span>
              <p>{company.business}</p>
            </li>
            <li>
              <span>【資本金】</span>
              <p>{company.capital}</p>
            </li>
            <li>
              <span>【連絡先】</span>
              <p>{company.contact}</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="access">
        <div className="access__inner">
          <div className="section-heading-blue">
            <h2>ACCESS</h2>
          </div>
          <div className="access-address">
            <span>{access.postalcode}</span>
            <p>{access.address}</p>
          </div>
          <iframe
            src={access.googlemap}
            title="access_map"
            width="100%"
            height="550"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  member: PropTypes.object,
  company: PropTypes.object,
  access: PropTypes.object,
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        member={frontmatter.member}
        company={frontmatter.company}
        access={frontmatter.access}
        helmet={
          <Helmet>
            <title>About | STINGS</title>
            <meta property="og:title" content="About | STINGS" />
            <meta name="twitter:title" content="About | STINGS"></meta>
          </Helmet>
        }
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        description
        member {
          ceo_name
          ceo_career
          ceo_twitter
          ceo_facebook
          cco_name
          cco_career
          cco_twitter
          cco_facebook
        }
        company {
          name
          establishment
          address
          business
          capital
          contact
        }
        access {
          postalcode
          address
          googlemap
        }
      }
    }
  }
`;
